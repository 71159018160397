body{
  transition: opacity 1s ease-in-out 0s, visibility 1s 0s;
  background: linear-gradient(55deg, #431F54 0%, #151226 60%, #17254A 100%);
  color: white;
  font-family: 'Lato', sans-serif;
  width: 100%;
  margin: 0;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.box {
  width: 700px;
  height: auto;
  border: 1px solid #ddd; 
  background-color: white; 
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  border-top-right-radius: 8px;
  border-top: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.tab-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 700px;
}

.tab {
  background-color: #e2e2e2;
  width: 150px;
  min-height: 50px;
  border: 1px solid #d6d6d6; 
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  text-align: center;

  display: flex;
  justify-content: center;
  align-items: center;
}

.tab:hover {
  background-color: #fff;
}

.tab:focus {
  outline: none;
}

.tab.active {
  background-color: #fff;
  font-weight: bold;
  border-bottom: none;
  border-top: 1px solid #ddd;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
}

/*INSIDE THE BOX*/
.upload-content-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.run-content-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
}

.upload-item-container {
  display: flex;
  flex-direction: row;
  justify-content: center; /* Center-align the container */
  align-items: center; /* Align items vertically */
  margin-top: 5px;
  margin-bottom: 5px;
  width: 100%;
}

.upload-item {
  cursor: pointer;
  display: flex;
  align-items: center; /* Ensure vertical alignment of text */
  flex-grow: 1; /* Take available space */
  color: #3B84FD;
  width: 80%;
}

.upload-voice {
  background: url('../src/Assets/voicefile.webp') no-repeat right center;
  background-size: 30px 30px;
  width: 20%;
  height: 30px;
  margin-right: 10px;
  
}

.upload-text {
  background: url('../src/Assets/textfile.webp') no-repeat right center;
  background-size: 30px 30px;
  width: 20%;
  height: 30px;
  margin-right: 10px;
}

.upload-scorecard {
  background: url('../src/Assets/scorecardfile.webp') no-repeat right center;
  background-size: 30px 30px;
  width: 20%;
  height: 30px;
  margin-right: 10px;
}

.content-header {
  font-size: 16px;
  padding: 5px;
  align-items: center;
}

.content-info {
  font-size: 16px;
  padding: 5px;
  align-items: center;
  text-align: center;
}

.file-names {
  border: 1px solid #ddd; 
  border-radius: 8px;
  padding: 5px;
  text-decoration: none;
  font-size: 12px;
  height: auto;
  overflow-y: scroll;
  max-height: 60px;
}

.upload-button {
  display: flex;
  flex-direction: row;
  padding: 5px;
  border: 1px solid #ddd; 
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  
}

.upload-button:hover {
  background-color: #f5f5f5;
}

.upload-button:active {
  border: 1px solid #ddd; 
  outline: none;
}

.upload-button:focus {
  border: 1px solid #ddd; 
  outline: none;
}

.run-button {
  display: flex;
  flex-direction: row;
  padding: 5px;
  border: 1px solid #ddd; 
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.run-button:hover {
  background-color: #f5f5f5;
}

.run-button:active {
  border: 1px solid #ddd; 
  outline: none;
}

.run-button:focus {
  border: 1px solid #ddd; 
  outline: none;
}

.tailspin {
  margin-right: 5px;
}

.run-content-item {
  font-weight: bold;
  font-size: 14px;
  margin-right: 10px;
}

.input-gray {
  font-weight: bold;
  font-size: 14px;
  margin-right: 10px;
  border: 1px solid rgb(204, 204, 204);
  padding: 7px;
  border-radius: 4px;
}

.input-gray:focus {
  outline: 2px solid #3B84FD;
  border: transparent;
}

.input-checkbox {
  font-weight: bold;
  font-size: 14px;
  margin-left: 30px;
  
  padding: 7px;
  border-radius: 4px;
}

.flex-row-container {
  display: flex;
  flex-direction: row;
  align-items:center;
  margin-bottom: 10px;
}

.download-button {
  display: flex;
  flex-direction: row;
  padding: 5px;
  border: 1px solid #ddd; 
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.download-button:hover {
  background-color: #f5f5f5;
}

.download-button:active {
  border: 1px solid #ddd; 
  outline: none;
}

.download-button:focus {
  border: 1px solid #ddd; 
  outline: none;
}